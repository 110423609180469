<template>
  <div class="container">
    <header class="header">
      <h1>{{ name }}</h1>
      <h2>{{ title }}</h2>
      <div class="contact-info">
        <p>Email: <a :href="`mailto:${email}`">{{ email }}</a></p>
        <p>LinkedIn: <a :href="linkedin" target="_blank">{{ linkedin }}</a></p>
        <p>GitHub: <a :href="github" target="_blank">{{ github }}</a></p>
        <p>Portfolio: <a :href="portfolio" target="_blank">{{ portfolio }}</a></p>
      </div>
    </header>
    <section class="section summary">
      <h2>Summary</h2>
      <p>{{ summary }}</p>
    </section>
    <section class="section skills">
      <h2>Skills</h2>
      <ul>
        <li v-for="skill in skills" :key="skill">{{ skill }}</li>
      </ul>
    </section>
    <section class="section experience">
      <h2>Experience</h2>
      <div v-for="job in experience" :key="job.title">
        <p>
          <strong>{{ job.title }}</strong><br />
          {{ job.company }}, {{ job.location }}<br />
          {{ job.startDate }} - {{ job.endDate }}<br />
          {{ job.description }}
        </p>
      </div>
    </section>
    <section class="section education">
      <h2>Education</h2>
      <div v-for="edu in education" :key="edu.degree">
        <p>
          <strong>{{ edu.degree }}</strong><br />
          {{ edu.university }}, {{ edu.location }}<br />
          {{ edu.startDate }} - {{ edu.endDate }}
        </p>
      </div>
    </section>
    <section class="section projects">
      <h2>Projects ({{ data.getProject.totalCount }})</h2>
      <div v-if="loading">Loading...</div>
      <div v-else-if="error">Error: {{ error.message }}</div>
      <div v-else class="scrollable">
        <div v-for="project in data.getProject.items" :key="project.id" class="project-card">
          <h3>{{ project.name }}</h3>
          <p><strong>Description:</strong> {{ project.description }}</p>
          <p><strong>Link:</strong> <a :href="project.link" target="_blank">{{ project.link }}</a></p>
        </div>
      </div>
    </section>
    <section class="section certifications">
      <h2>Certifications ({{ data.getCertificate.totalCount }})</h2>
      <div v-if="loading">Loading...</div>
      <div v-else-if="error">Error: {{ error.message }}</div>
      <div v-else class="scrollable">
        <div v-for="cert in data.getCertificate.items" :key="cert.id" class="cert-card">
          <h3>{{ cert.name }}</h3>
          <p><strong>Date:</strong> {{ cert.date }}</p>
          <p><strong>Credential Url:</strong> <a :href="cert.credUrl" target="_blank">{{ cert.credUrl }}</a></p>
        </div>
      </div>
    </section>
    <section class="section languages">
      <h2>Languages</h2>
      <div v-for="language in languages" :key="language.name">
        <p>{{ language.name }}: {{ language.proficiency }}</p>
      </div>
    </section>
    <section class="section interests">
      <h2>Interests</h2>
      <div v-for="interest in interests" :key="interest">
        <p>{{ interest }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import { useQuery } from '@vue/apollo-composable';
import {GET_DATA} from '../apollo/queries'


export default {
  name: 'MyResume',
  metaInfo: {
    title: 'About Us'   
   },

  data() {
    return {
      name: 'Belit B. Kış',
      title: 'Software Developer',
      email: 'belitberdelk@gmail.com',
      linkedin: 'https://www.linkedin.com/in/belit-k/',
      github: 'https://github.com/BelitK',
      portfolio: 'https://belitk.com',
      summary:
        'Highly skilled software developer with extensive experience in Robotic Process Automation (RPA), robotics (including autonomous drones), Internet of Things (IoT), Python backend development, and artificial intelligence (AI). Proficient in designing and implementing robust automation solutions, integrating complex systems, and leveraging AI technologies for innovative applications. Seeking to advance my career by focusing on advanced technical roles, specializing in AI, robotics, and software development, and contributing to cutting-edge projects. Committed to continuous learning and staying updated with the latest technological advancements.',
      skills: [
        'Programming Languages: Python, JavaScript, C++',
        'Frameworks & Libraries: React, TensorFlow, FastAPI, GraphQL, Django, Flask, PostgreSQL',
        'Tools & Platforms: Git, Docker, AWS, Azure, GCP, Airflow',
        'Soft Skills: Team Collaboration, Problem Solving, Time Management, Communication'
      ],
      experience: [
        {
          title: 'Freelance Software Developer',
          company: 'Freelance',
          location: 'Remote',
          startDate: '06/2023',
          endDate: '',
          description:
            'Developed and maintained web applications using [technologies]. Collaborated with cross-functional teams to define, design, and ship new features. Implemented responsive design to enhance user experience on mobile devices. [Add other relevant achievements or responsibilities].'
        },
        {
          title: 'Python & RPA Developer',
          company: 'Kronnika',
          location: 'Istanbul / Turkey',
          startDate: '11/2021',
          endDate: '05/2023',
          description:
            'Managed a team of [number] developers in [project or task]. Increased application performance by [percentage] through [specific action]. [Add other relevant achievements or responsibilities].'
        }
      ],
      education: [
        {
          degree: 'Bachelors',
          university: 'Beykent University',
          location: 'Istanbul / Turkey',
          startDate: '09/2017',
          endDate: '11/2021'
        }
      ],
      languages: [
        {
          name: 'English',
          proficiency: 'Fluent'
        },
        {
          name: 'Turkish',
          proficiency: 'Fluent'
        }
      ],
      interests: ['Robotic', 'A.I.', 'Open-Source']
    };
  },
  setup() {
    const { result: result, loading: loading, error: error } = useQuery(GET_DATA);

    return {
      data: result,
      loading,
      error,
    };
  }
};
</script>

<style scoped>
.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  padding: 20px;
}
.header,
.section {
  margin-bottom: 40px;
}
.header h1,
.header h2 {
  margin: 0;
  padding: 0;
}
.header h1 {
  font-size: 2.5em;
}
.header h2 {
  font-size: 1.5em;
  color: #777;
}
.contact-info {
  margin-top: 10px;
}
.contact-info p {
  margin: 0;
  padding: 0;
}
.section h2 {
  font-size: 1.8em;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.skills ul,
.projects ul {
  list-style: none;
  padding: 0;
}
.skills li,
.projects li {
  background: #f4f4f4;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ddd;
}
.scrollable {
  max-height: 400px;
  overflow-y: auto;
}
.project-card,
.cert-card {
  background: #f4f4f4;
  padding: 20px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: calc(45% - 20px);
  display: inline-block;
  vertical-align: top;
}
.project-card h3,
.cert-card h3 {
  margin-top: 0;
}
</style>
