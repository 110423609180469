import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import apolloClient from './apollo/client';
const app = createApp(App);

// src/main.js
app.use(apolloClient)
app.use(router)
app.mount('#app');

