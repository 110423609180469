import { ApolloClient, InMemoryCache } from '@apollo/client/core';
import { createHttpLink } from '@apollo/client/link/http';
import { ApolloClients } from '@vue/apollo-composable';

const httpLink = createHttpLink({
  uri: 'http://api:1000/graphql',
});

const cache = new InMemoryCache();

const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
});

export default {
  install(app) {
    app.provide(ApolloClients, {
      default: apolloClient,
    });
  },
};