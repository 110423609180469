import gql from 'graphql-tag';

export const GET_DATA = gql`
query GetProject {
  getProject {
    totalCount
    items {
      name
      id
      description
      link
    }
  }
  getCertificate {
    totalCount
    items {
      name
      id
      date
      credUrl
    }
  }
}
`;