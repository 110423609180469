// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/index.vue'; // Home component
import MyResume from './components/Resume.vue';   // New page component

const routes = [
  { path: '/', component: HomePage },
  { path: '/new', component: MyResume }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
