<!-- src/components/NewPage.vue -->
<template>
  <div>
    <h1>Hi!</h1>
    <h2>Welcome To My Website.</h2>
    <p>I'm Belit, Just developing stuff.
      I like cats 😺, and more cats 😸</p>
      <a href="https://www.credly.com/badges/0eb80a7d-aaa5-40d1-b46d-1fc8f6eb8e2d/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/a8e890b4-d484-4e04-b521-fba516a8c3cd/coursera-specialization-badge.png" alt="Open Source Software Development, Linux and Git Specialization badge image. Learning. Foundational level. Issued by Coursera" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/32c76830-02e2-4de0-be9a-6d9576fd30a9/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/ae2f5bae-b110-4ea1-8e26-77cf5f76c81e/GCC_badge_IT_Support_1000x1000.png" alt="Google IT Support Certificate badge image. Learning. Issued by Coursera" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/bbe95a27-287b-401f-a145-e74458dda89b/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/efbdc0d6-b46e-4e3c-8cf8-2314d8a5b971/GCC_badge_python_1000x1000.png" alt="Google IT Automation with Python Professional Certificate badge image. Learning. Issued by Coursera" width="200" height="200"></a>
<br>
      <a href="https://www.credly.com/badges/b27fbbe0-8829-4a67-808a-b7ca6edc4f9c/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/f79c413f-9af6-4662-be06-1ed8d34e998a/image.png" alt="Fundamentals of Scalable Data Science badge image. Validation. Intermediate level. Issued by Coursera" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/2d9ddb4f-bd92-441e-88f4-7492a9649eba/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/007afae6-2754-4a7c-9c44-e95c64c93656/IBM_Watson_IoT-_Advanced_Machine_Learning_and_Signal_Processing.png" alt="Advanced Machine Learning and Signal Processing badge image. Validation. Intermediate level. Issued by Coursera" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/94a0f774-310a-4909-bd8f-282f5cc0c6a0/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/c2cc3091-c033-40c0-9cfc-2d5d78a3fa30/Applied_AI_with_Deep_Learning.png" alt="Applied AI with DeepLearning badge image. Validation. Intermediate level. Issued by Coursera" width="200" height="200"></a>
<br>
      <a href="https://www.credly.com/badges/5614d34f-be57-489b-9230-593375a0f55f/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/fce226c2-0f13-4e17-b60c-24fa6ffd88cb/Intro2IoT.png" alt="Introduction to IoT badge image. Issued by Cisco" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/2892de3e-69ce-4817-b733-ccb57f0f6ef3/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/af8c6b4e-fc31-47c4-8dcb-eb7a2065dc5b/I2CS__1_.png" alt="Introduction to Cybersecurity badge image. Issued by Cisco" width="200" height="200"></a>
      <a href="https://www.credly.com/badges/aeec7df3-71ad-4b58-bc38-3fe2bd5c5627/public_url" target="_blank"><img  src="https://images.credly.com/size/680x680/images/054913b2-e271-49a2-a1a4-9bf1c1f9a404/CyberEssentials.png" alt="Cybersecurity Essentials badge image. Issued by Cisco" width="200" height="200"></a>

      </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      externalLink: '/new'
    };
  }
  
};
</script>

